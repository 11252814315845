<script setup>
import { ref } from 'vue';
import { getOrCreateDataObject } from 'o365.vue.ts';
import $t from "o365.modules.translate.ts";
import confirm from "o365.controls.confirm.ts";
import Tasks from "sja.vue.components.Tasks.vue";
import Reasons from "sja.vue.components.Reasons.vue";
import { procCreateSJA, procDeleteSJA } from 'sja.vue.modules.utilities.js'

const props = defineProps({
        itemId: Number,
        stepId: Number,
        readOnly: Boolean,
        workflows: Object,
        objects: Object,
});

let isSJA = ref(false);
let SJA_ID = null;

const dsSJA = getOrCreateDataObject({
        id: `dsSJA_${props.itemId}${props.stepId ? '_' + props.stepId : ''}`,
        uniqueTable: 'atbv_SJA_SJA',
        selectFirstRowOnLoad: true,
        maxRecords: 1,
        viewName: 'aviw_SJA_SJA',
        allowUpdate: true,
        allowInsert: true,
        allowDelete: true,
        disableLayouts: true,
        fields: [
                { name: 'PrimKey', },
                { name: 'ID', },
                { name: 'Step_ID', },
                { name: 'Name', },
                { name: 'Description', },
                { name: 'Planned', },
                { name: 'Completed', },
                { name: 'OrgUnit_ID', },
                { name: 'OrgUnitIdPath', },
                { name: 'OrgUnit', },
                { name: 'Responsible_ID', },
                { name: 'Responsible', },
                { name: 'IsTemplate', },
                { name: 'Deleted', },
                { name: 'Checklist_ID', },
                { name: 'CheckList_Editable', },
                { name: 'Checklist', }
        ],
        whereClause: `Deleted = 0 ${props.stepId ? "AND Step_ID = " + props.stepId : 'AND Workflow_ID = ' + props.itemId + ' AND Step_ID IS NULL'}`
});

async function checkForSJA() {
        await dsSJA.load();
        if (dsSJA.data.length > 0) {
                SJA_ID = String(dsSJA.current.ID);
                isSJA.value = true;
                if (props.readOnly == true && !dsSJA.current.Completed) {
                        dsSJA.current.Completed = new Date().toUTCString();
                        dsSJA.save();
                }
        } else {
                isSJA.value = false;
        }
}

async function createSJA() {
        confirm({
                title: $t("Starting a Safe Job Analysis"),
                message: $t("You are now creating a SJA for this step.") + ' ' + $t("A SJA is a step by step risk evaluation to be performed before work commences") + ' ' + $t("on a task to reduce the risk to workers by determining hazards and taking actions to improve the potential outcome of an incident.") + ' ' + $t("Are you sure you wish to create a SJA for this step?"),
                btnTextOk: $t("Yes"),
                btnTextCancel: $t("No"),
        }).then(async () => {
                await procCreateSJA.execute({ Step_ID: props.stepId ? props.stepId : null, Item_ID: props.itemId });
                checkForSJA();
        }).catch(() => {
        });
}

async function deleteSJA() {
        confirm({
                title: $t("Delete Safe Job Analysis"),
                message: $t('Are you sure you wish to delete this SJA?'),
                btnTextOk: $t("Yes"),
                btnTextCancel: $t("No"),
        }).then(async () => {
                await procDeleteSJA.execute({ SJAID: dsSJA.current.ID, SJAPrimKey: dsSJA.current.PrimKey });
                await dsSJA.load()
                checkForSJA();
        }).catch(() => {
        });
}

checkForSJA();

</script>

<template>
        <div class="d-flex"> <button class="btn btn-primary create-sja"
                        @click="createSJA()" v-if="isSJA == false && readOnly == false"><i class="bi bi-cone-striped me-1"></i>{{ $t('Create SJA') }}</button>
        </div>
        <div v-if="isSJA" class="d-flex flex-column">
                <div class="mb-4">
                        <button @click="deleteSJA()" v-if="!dsSJA.current.Completed" class="btn btn-outline-danger btn-sm">
                                {{ $t('Delete SJA') }}
                        </button>
                </div>
                <div>
                        <Reasons :readOnly="!dsSJA.current.Completed ? false : true" :sjaID="SJA_ID"></Reasons>
                </div>
                <div class="mb-1">
                        <h5 class="mt-3">{{ $t('Tasks') }}</h5>
                </div>
                <div class="overflow-x-auto">
                        <Tasks :readOnly="!dsSJA.current.Completed ? false : true" :sjaID="SJA_ID"></Tasks>
                </div>
        </div>
</template>